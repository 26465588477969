/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */

/* Button Styles */

.btn-dribble {
  background-color: #e49ef1 !important;
}

.btn-default {
  background-color: #b1b1b1 !important;
}

/* End of Button Styles */

.navbar.navbar-transparent {
  background-color: #A9E5FF;
}

.main-content.overlap {
  border-top-color: #A9E5FF;
}


/* Leaflet Specific */

.popupContent .geofence-item {
  white-space: nowrap;
  width: 100%;
  display: inline-block;
  position: relative;
  min-width: 180px;

  span.name {
    text-overflow: ellipsis;
    max-width: 100px;
    overflow: hidden;
    display: inline-block;
    line-height: 14px;
  }

  span.time {
    color: red;
    text-align: right;
    position: absolute;
    right: 0;
  }
}

.popupContent .alignLeft {
  float: left;
}

.popupContent .alignRight {
  float: right;
  font-weight: bold;
}

.popupContent p {
  margin: 1.5px !important;
  padding: 1.5px !important;
}

.popupContent .header {
  color: #191B1D;
  text-align: left;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px !important;
}

.popupContent .leaflet-popup-content {
  min-width: 180px;
  //max-width: 180px;
}

.popupContent .leaflet-popup-content-wrapper {
  background: #FFF;
  color: #000;
  font-size: 10px;
  line-height: 20px;
  border-radius: 0;
}

.popupContent .leaflet-popup-content-wrapper a {
  color: rgba(255, 255, 255, 0.1);
}

.popupContent .leaflet-popup-tip-container {
  width: 30px;
  height: 15px;
}

.popupContent .leaflet-popup-tip {
  background: transparent;
  border: none;
  box-shadow: none;
}

.legend {
  padding: 6px 8px;
  font: 10px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  line-height: 24px;
  color: #555;
}

.legend span {
  position: relative;
  bottom: 3px;
}

.legend img {
  width: 19px;
  height: 19px;
  float: left;
  margin: 0 8px 0 0;
  opacity: 0.7;
}

.polylineStyle {
  color: #ff7800;
  opacity: 0.65;
}

/* End Of Leaflet Specific */


/* Time line */
clr-icon {
  display: inline-block;
  margin: 0;
  height: 16px;
  width: 16px;
  vertical-align: middle;
  fill: currentColor
}

clr-icon .transparent-fill-stroke {
  stroke: currentColor
}

clr-icon.is-green, clr-icon.is-success {
  fill: #2f8400
}

clr-icon.is-green .transparent-fill-stroke, clr-icon.is-success .transparent-fill-stroke {
  stroke: #2f8400
}

clr-icon .clr-i-badge, clr-icon.is-danger, clr-icon.is-error, clr-icon.is-red {
  fill: #e12200
}

clr-icon.is-danger .transparent-fill-stroke, clr-icon.is-error .transparent-fill-stroke, clr-icon.is-red .transparent-fill-stroke {
  stroke: #e12200
}

clr-icon.is-warning {
  fill: #fac400
}

clr-icon.is-warning .transparent-fill-stroke {
  stroke: #fac400
}

clr-icon.is-blue, clr-icon.is-info {
  fill: #0079b8
}

clr-icon.is-blue .transparent-fill-stroke, clr-icon.is-info .transparent-fill-stroke {
  stroke: #0079b8
}

clr-icon.is-inverse, clr-icon.is-white {
  fill: #fff
}

clr-icon.is-inverse .transparent-fill-stroke, clr-icon.is-white .transparent-fill-stroke {
  stroke: #fff
}

clr-icon.is-highlight {
  fill: #0079b8
}

clr-icon.is-highlight .transparent-fill-stroke {
  stroke: #0079b8
}

clr-icon[dir=up] svg, clr-icon[shape$=' up'] svg {
  transform: rotate(0deg)
}

clr-icon[dir=down] svg, clr-icon[shape$=' down'] svg {
  transform: rotate(180deg)
}

clr-icon[dir=right] svg, clr-icon[shape$=' right'] svg {
  transform: rotate(90deg)
}

clr-icon[dir=left] svg, clr-icon[shape$=' left'] svg {
  transform: rotate(270deg)
}

clr-icon[flip=horizontal] svg {
  transform: scale(-1) rotateX(180deg)
}

clr-icon[flip=vertical] svg {
  transform: scale(-1) rotateY(180deg)
}

clr-icon .clr-i-badge .transparent-fill-stroke {
  stroke: #e12200
}

clr-icon > * {
  height: 100%;
  width: 100%;
  display: block;
  pointer-events: none
}

clr-icon > svg {
  transition: inherit
}

clr-icon .clr-i-solid, clr-icon .clr-i-solid--alerted, clr-icon .clr-i-solid--badged, clr-icon > svg title {
  display: none
}

clr-icon .clr-i-outline--alerted:not(.clr-i-outline), clr-icon .clr-i-outline--badged:not(.clr-i-outline), clr-icon.is-solid .has-solid .clr-i-solid--alerted:not(.clr-i-solid), clr-icon.is-solid .has-solid .clr-i-solid--badged:not(.clr-i-solid), clr-icon[class*=has-alert] .can-alert .clr-i-outline:not(.clr-i-outline--alerted), clr-icon[class*=has-badge] .can-badge .clr-i-outline:not(.clr-i-outline--badged) {
  display: none
}

clr-icon.is-solid .has-solid .clr-i-solid, clr-icon.is-solid[class*=has-badge] .can-badge.has-solid .clr-i-solid--badged, clr-icon[class*=has-alert] .can-alert .clr-i-outline--alerted, clr-icon[class*=has-badge] .can-badge .clr-i-outline--badged {
  display: block
}

clr-icon.is-solid .has-solid .clr-i-outline, clr-icon.is-solid .has-solid .clr-i-outline--badged {
  display: none
}

clr-icon.is-solid[class*=has-badge] .can-badge.has-solid .clr-i-outline, clr-icon.is-solid[class*=has-badge] .can-badge.has-solid .clr-i-outline--badged, clr-icon.is-solid[class*=has-badge] .can-badge.has-solid .clr-i-solid:not(.clr-i-solid--badged) {
  display: none
}

clr-icon.is-solid[class*=has-alert] .can-alert.has-solid .clr-i-solid--alerted {
  display: block
}

clr-icon.is-solid[class*=has-alert] .can-alert.has-solid .clr-i-outline, clr-icon.is-solid[class*=has-alert] .can-alert.has-solid .clr-i-outline--alerted, clr-icon.is-solid[class*=has-alert] .can-alert.has-solid .clr-i-solid:not(.clr-i-solid--alerted) {
  display: none
}

clr-icon.has-badge--success .clr-i-badge {
  fill: #2f8400
}

clr-icon.has-badge--success .clr-i-badge .transparent-fill-stroke {
  stroke: #2f8400
}

clr-icon.has-badge--error .clr-i-badge {
  fill: #e12200
}

clr-icon.has-badge--error .clr-i-badge .transparent-fill-stroke {
  stroke: #e12200
}

clr-icon.has-badge--info .clr-i-badge {
  fill: #0079b8
}

clr-icon.has-badge--info .clr-i-badge .transparent-fill-stroke {
  stroke: #0079b8
}

clr-icon.has-alert .clr-i-alert {
  fill: #fac400
}

clr-icon.has-alert .clr-i-alert .transparent-fill-stroke {
  stroke: #fac400
}

clr-icon .is-off-screen {
  position: fixed !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  left: 0 !important;
  top: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  margin: 0 0 -1px !important
}

.clr-timeline {
  display: flex;
  padding: .5rem
}

.clr-timeline-step {
  display: flex;
  position: relative
}

.clr-timeline-step clr-icon {
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
  min-height: 1.5rem
}

.clr-timeline-step clr-icon[shape="spinner"] {
  animation: spin 1s linear infinite
}

.clr-timeline-step clr-icon[shape="circle"] {
  color: #FFF
}

.clr-timeline-step clr-icon[shape="dot-circle"] {
  color: #0079b8
}

.clr-timeline-step clr-icon[shape="success-standard"] {
  color: #60b515
}

.clr-timeline-step clr-icon[shape="error-standard"] {
  color: #c92100
}

.clr-timeline-step-header {
  font-size: .54167rem;
  color: #191B1D;
  line-height: .75rem
}

.clr-timeline-step-title {
  font-size: .64167rem;
  font-weight: 500;
  color: #191B1D;
  line-height: .75rem;
  margin-bottom: .25rem
}

.clr-timeline-step-description {
  font-size: .55833rem;
  color: #838689;
  line-height: .66667rem
}

.clr-timeline-step-description button {
  display: block;
  margin-top: .33333rem
}

.clr-timeline-step-description img {
  width: 100%;
  margin-top: .33333rem
}

.clr-timeline.clr-timeline-horizontal .clr-timeline-step {
  flex-direction: column;
  width: 100%;
  min-width: 7.29167rem;
  margin-left: .5rem
}

.clr-timeline.clr-timeline-horizontal .clr-timeline-step:not(:last-of-type) .clr-timeline-step-body::before {
  content: '';
  position: absolute;
  background: #9a9a9a;
  height: .08333rem;
  width: calc(100% - (1.5rem - 2 * .08333rem) + .5rem);
  transform: translate(1.41667rem, -0.79167rem)
}

.clr-timeline.clr-timeline-horizontal .clr-timeline-step-header {
  white-space: nowrap;
  margin-bottom: .33333rem
}

.clr-timeline.clr-timeline-horizontal .clr-timeline-step-body {
  display: flex;
  flex-direction: column
}

.clr-timeline.clr-timeline-horizontal .clr-timeline-step-title {
  margin-top: .33333rem
}

.clr-timeline.clr-timeline-vertical {
  flex-direction: column;
  min-width: 13.33333rem
}

.clr-timeline.clr-timeline-vertical .clr-timeline-step {
  flex-direction: row
}

.clr-timeline.clr-timeline-vertical .clr-timeline-step:not(:last-of-type) {
  margin-bottom: 1.5rem
}

.clr-timeline.clr-timeline-vertical .clr-timeline-step:not(:last-of-type) .clr-timeline-step-body::before {
  content: '';
  position: absolute;
  background: #9a9a9a;
  width: .08333rem;
  height: calc(100% + 2 * .08333rem);
  transform: translate(-1.29167rem, 1.16667rem)
}

.clr-timeline.clr-timeline-vertical .clr-timeline-step-header {
  text-align: right;
  min-width: 2.5rem;
  width: 2.5rem;
  margin-right: .5rem;
  margin-top: .25rem
}

.clr-timeline.clr-timeline-vertical .clr-timeline-step-body {
  display: flex;
  flex-direction: column;
  min-width: 7.33333rem;
  margin-left: .5rem;
  margin-top: .25rem
}

@keyframes spin {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

/* End of Time line */

.leaflet-reset-button {
  background-color: #fff;
  //border-bottom: 1px solid #ccc;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
  padding: 6px 10px 6px;
  border-radius: 4px;
  border: 1px grey solid;
}
